html, body {
    height: 100%;
}

.login {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f9f9f9 !important;
    background-size: cover;
    background-position: center center;
    background-repeat: repeat;
    height: 100vh;

}

@media screen and (max-width: 768px ) {
    .align-me {
        text-align: center
    }
}