.forgotten_pass {
    height: 100vh;
    background: #0e171d;
    position: relative;
    color: #fff;
}

.forgotten {
    position: relative;
    top: 50px;
}

.forgotten_pass label {
    color: #fff;
}

.forgotten_pass .form-control {
    height: 50px;
    border-radius: 0px;
}

.forgotten_pass .btn-block {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 0px;
    margin-top: 15px;
}

.forgotten_pass .push-down {
    margin-top: 40px;
}

@media screen and (max-width: 768px) {
    .forgotten_pass {
        background: #0e171d;
        position: relative;
    }
}

.forgotten_pass h1 {
    font-size: 5em;
    font-weight: 900;
}

.forgotten_pass h5 {
    font-size: 1em;
    font-weight: 500;
}


@media screen and (max-width: 768px) {
    .forgotten_pass h1 {
        font-size: 3em;
        font-weight: 900;
    }

    .forgotten_pass h5 {
        font-size: 1em;
        font-weight: 500;
    }

    .forgotten_pass .push-down {
        margin-top: 20px;
    }

    .forgotten {
        position: relative;
        top: 0px;
    }
}