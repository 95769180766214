.carousel-indicators{
display: none;
}

.carousel-control.left {

display: none;
}
.carousel-control.right {

display: none;
}