.estimate {
    background-color: #0E171D;
    color: white;
    padding: 100px 0;
}

.estimate label {
    padding-left: 15px;
    font-size: 12px;
    color: white;
}

.estimate .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    color: white;
    font-size: 14px;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}

.estimate option {
    color: black;
}

.estimate .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: white;
    cursor: default;
    background-color: #21ad21;
    font-weight: 800;
    border: none;
    border-bottom-color: transparent;
}

.estimate h1 {
    font-weight: 400;
}

.estimate p {
    color: white;
}

.estimate a {
    color: white;
    font-size: 15px;
}

.estimate .form-group {
    padding-top: 50px;
}

.estimate .price_btn {
    padding-top: 100px;
}

.map {
    height: 338px;
    background-size: cover;
    background-repeat: no-repeat;
}

.di {
    display: none;
}

.visibility {
    visibility: hidden;
}


@media screen and (max-width: 768px) {
    .estimate .form-group {
        padding-top: 0px;
    }

    .estimate .price_btn {
        padding-top: 0px;
    }
}