.adjust h1 {
    color: #1e1d1d;
    font-size: 35px;
}

@media screen and (min-width: 1200px) {
    .adjust {
        margin-top: 50px;
    }

    .adjust h1 {
        color: #1e1d1d;
        font-size: 35px;
    }
}


@media screen and (max-width: 768px) {
    .adjust {
        margin-top: 0px;
    }
}



.how .carousel-indicators {
    position: absolute;
    bottom: 180px;
    left: 53%;
    z-index: 15;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;
    text-align: center;
    list-style: none;
}