.btn-success {
    background-color: #21ad21;
    border-radius: 0px;
  }

  p {
    font-size: 13px;
  }

  .myposition {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    width: 10%;
    height: 50px;
    font-size: 3rem;
    color: #21ad21;
  }