@font-face {
    font-family: "SF Pro Text";
    src: url('../fonts/proxima_nova_reg-webfont.ttf') format("truetype");
    src: url('../fonts/SF-Pro-Text-Medium.otf') format("opentype");
}


html{
  height:100%
}
body{
  height:100%;
  font-family: 'SF Pro Text';
  margin:0;
  padding: 0;
  font-size: 16px;
}
.intro{
  height:100%
}

strong{
  font-weight: 400;
}

p {
    color: rgb(102, 102, 102);
    font-family: 'SF Pro Text';
    font-size: 12px;
    line-height: 1.38105;
    font-weight: 400;
    letter-spacing: 0.011em;
}

h1, h2, h3, h4, h5, h6 {
   font-family: 'SF Pro Text';
   font-weight: 500;
}



a {

    color:#21ad21;
}

a:hover {

    color: #000;
}

.features a {
   font-size: 14px;
}

.features a:hover {
   color: #000;
}

.features p{
  font-weight: 400;
  font-size: 16px !important;
}


.home-left{
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-image: url(../images/banner_shipper.jpg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.home-left-trucking {
  background-image: url(../images/truck_bg.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.home-left-rail {
  background-image: url(../images/rail_partners_banner.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.home-left-barge {
  background-image: url(../images/barge_partners_banner.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.home-left-driver {
  background-image: url(../images/new_driver_bg.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.new-shipper{
  background-image: url(../images/banner_shipper.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}
.new-carrier{
  background-image: url(../images/bg_test.jpg);
  display : flex;
  justify-content : center;
  align-items : center;
  background-color:#21ad21;
  height : 100%;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
}

.mobile {
	background-image: url(../images/cargo-ship.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}

.mobile-trucking{
   background-image: url(../images/trucking_partners_banner_mobile.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-rail{
   background-image: url(../images/rail_partners_banner_mobile.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-barge{
   background-image: url(../images/barge_partners_banner_mobile.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-driver{
   background-image: url(../images/new_driver_bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-carrier{
   background-image: url(../images/bg_test.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-shipper{
   background-image: url(../images/banner_shipper.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 200px 0;
}
.mobile-trucking h2 {
   
    font-size: 50px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-trucking p {

  font-size: 15px;
   
    font-weight: 400;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-rail h2 {
   
    font-size: 50px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-rail p {

  font-size: 15px;
   
    font-weight: 400;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-driver h2 {
   
    font-size: 50px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-driver p {

  font-size: 15px;
   
    font-weight: 400;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-barge h2 {
   
    font-size: 50px;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile-barge p {

  font-size: 15px;
   
    font-weight: 400;
    color: white;
    text-shadow: 1px 1px black;
}

.mobile h2 {
   
    font-size: 30px;
    font-weight: 600;
    color: white;
    text-shadow: 1px 1px black;
}
.mobile p {

	  font-size: 13px;
    color: white;
    text-shadow: 1px 1px black;
}
.home-right{
  background-color:#FFF;
  height : 100%;
  position: relative;
}

.home-right h1 {
	font-size: 90px;
	text-align: right;
	margin: auto;
	color:#333;
	position: relative;
	top:150px;
	right:30px;
}
.home-right p {
	padding-top:20px;
  padding-left: 30px;
	font-size: 15px;
	text-align:right;
	color:#333;
	position: relative;
	margin: 0 auto;
	top: 150px;
	right: 30px
}

.about{

  color : #fff;
  background-image: url(../images/abstract2.jpg);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}

.about h4 {
	font-size: 30px;
	text-align: center;
	padding: 80px 0;
}

.how {
	padding:  0px 0;
}

.how h1, .how h2 {

   color: #1e1d1d;
   /**text-transform: uppercase;**/
}



.use{
  background-color:#21ad21;
  padding: 70px 0;
}

.use p {

    color: white;
}
.use h3 {

    color: white;
}


.my-close{
    z-index:999;
}

.download {
   width: 35%;
   float: right;
}

/******Navigation *********/
.navbar-default {
    background-color: transparent;
    border-color: transparent;
    z-index: 99;
    padding: 20px 50px 20px 50px;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color:#21ad21;
}

.navbar-default .navbar-nav>li>a {
    color: white;
    font-weight: 400;
    font-family: 'SF Pro Text';
    font-size: 16px;
}
.navbar-default .navbar-nav>li>a:hover {
    color: white;
    font-weight: 400;
    font-family: 'SF Pro Text';
    font-size: 16px;
}
.navbar-default .navbar-nav>li:hover {
    border-bottom: 3px solid #228B22;
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #fff;
    background-color: transparent;
    border-bottom: 3px solid #21ad21;
}



.navbar-toggle .one {
	 width: 40px;
}
.navbar-toggle .three {
	 width: 30px;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

.navbar-inverse {
    background-color: transparent;
    border-color: transparent;
    padding: 15px 50px 15px 50px;
}

.navbar-inverse .navbar-nav>li>a {
   color: black;
   font-weight: 400;
   font-size: 14px;
}
.navbar-inverse .navbar-nav>li>a:hover {
   color: black;
   font-weight: 400;
   font-size: 14px;
}
.navbar-inverse .navbar-nav>li:hover {
    border-bottom: 3px solid #228B22;
    background-color: transparent;
    color: #000;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: #000;
    background-color: transparent;
    border-bottom: 3px solid #21ad21;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    background-color: transparent;
}

.carousel-control.left {
    background-image: none;
    background-image:none;
    background-image:none;
    background-image: none;
    filter: none;
    background-repeat: none;
}
.carousel-control.right {
    right: 0;
    left: auto;
    background-image: none;
    background-image: none;
    background-image: none;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
    background-repeat: repeat-x;
}

.glyphicon-chevron-right {
	color: black;
}


.carousel-indicators li {
    display: inline-block;
    background-color: red;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000;
    background-color: white;
    border: 2px solid #21ad21;
    border-radius: 10px;
}


.carousel-indicators .active {
	background-color:#21ad21;
}

.stat {
	background-color: #fbfbfb;
	padding: 50px 0;
  /*background-image: url(../images/parallax_bg.jpg);*/
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.stat-num {
	 font-size: 50px;
   font-weight: 700;
   color:#21ad21;
}

.stat-text {
	font-size: 20px;
  font-weight: 700;
	color: black;
}


.btn-success {
	background-color:#21ad21;
	border-radius: 100px;
  border: none;
	color: white !important;
  padding: 10px 30px;
  transition: 0.5s ease-in;
  font-size: 14px;
  font-weight: 500;
  border: none;
  transition: 1s ease-out;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;

}

.btn-success:hover {
  background-color: #0a3f1f;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}
.btn-success:focus {
  background-color: #000;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}
.btn-success:active {
  background-color: #000;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}


.nav>li:hover {
  background-color: #0fb90f;

}

.glyphicon {
   opacity:0.3;
   font-size: 12px;
}


.become{
  /**background-color: #0a3f1f;**/
  background-image: linear-gradient(to right bottom, #098509, #087d0a, #07750a, #066e0a, #06660a);
}
.become .cols {
	 padding: 100px 0;
	 text-align: center;
}

.become p {
   color: white;
}


.options {
   padding: 100px 0;
  border-radius: 0;
  
}

.options h2 {
   
   color: #000;
}
.shipper {
   height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
}

.shipper h1 {
   font-size: 130px;
   font-weight: 900;
   color: black;
}

.shipper p {
   font-size: 25px;
   font-weight: 900;
   color: black;
}

/**.form-shipper {
   background-color: white;
   padding: 50px 20px;
   border-radius: 5px;
   box-shadow: 3px 0 50px rgba(115,115,115,.8);
}**/

.partner {
   height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
}
.driver {
   height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   background-color: #f2f2f2 !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
}

.partner h1 {
   font-size: 130px;
   font-weight: 900;
   color: black;
}

.partner p {
   font-size: 25px;
   font-weight: 900;
   color: black;
}
.driver h1 {
   font-size: 130px;
   font-weight: 900;
   color: black;
}

.driver p {
   font-size: 25px;
   font-weight: 900;
   color: black;
}

.form-shipper {
   background-color: white;
   padding: 0px 20px 0px 20px;
   border-radius: 5px;
  box-shadow: 3px 0 50px rgba(115,115,115,.8);
  margin-top: -20px;
}

footer {
	background-color: #262626;
	padding: 70px 0px;
  font-weight: 400;
  font-size: 14px;
}

footer ul li {
	list-style: none;
}

footer h3 {
	color: #fff;
  font-weight: 400;
}

footer a {
  color: white;
}
footer a:hover {
  color: #21ad21;

}

footer p {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}

footer li {
	text-align: left;
	color: white;
}

footer ul {
	    padding-inline-start: 0px;
}



.item h1{
  font-size: 50px;
}

@media screen and (max-width: 768px){
	.become .cols {
	 padding: 10px 0;
	 text-align: center;
   }
   .carousel-indicators {
   	  display: none;
   }
   

   .media-heading {
      color:#228B22;
      font-weight: 800;
      font-size: 20px !important;
    }
    .navbar-default {
    background-color: white;
  }

  .shipper h1 {
   font-size: 70px;
   font-weight: 900;
   color: white;
  }

  .shipper p {
     font-size: 23px;
     font-weight: 900;
     color: white;
  }
  /**
  .form-shipper {
     background-color: white;
     padding: 0px 20px 0px 20px;
     border-radius: 5px;
     margin-top: 20px;
  }**/
  .partner h1 {
   font-size: 70px;
   font-weight: 900;
   color: white;
  }

  .partner p {
     font-size: 23px;
     font-weight: 900;
     color: white;
  }
  .driver h1 {
     font-size: 70px;
     font-weight: 900;
     color: white;
  }

  .driver p {
     font-size: 23px;
     font-weight: 900;
     color: white;
  }

  .tpad {
     padding-top: 250px;
  }

  
}

@media (max-width: 1200px) { 
  .left{
    height:50%
  }
  .right{
    height:50%
  }
}

@media screen and (max-width: 769px){
	.move-phone {
		text-align: center
	}
}




.form-control {
  border-radius: 0px;
  height: 40%;
  padding: 8px;
}

label {
  color: grey;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 5px;

}

.form-group{
  margin-bottom: 5px;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #ffffff !important;
    /*background-image: url(../images/bg-logistics-2.png);*/
    background-position: center;
    background-size: cover;

}

.login .try{

    background-color: white;
    padding: 0px;
    box-shadow: 3px 0 50px rgba(115,115,115,.8);

}

.login label {
  color: #000;
  font-weight: 800;
}
.login p {
  color: #000;
}

.media-body h4{
  font-weight: 400;
  color: #21ad21;
}

@media screen and (max-width: 768px){
    .login {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff !important;
    }

    .login .try{
      background-color: white;
    }
}

.btn-info {
  background-color: orange;
  border-radius: 100px;
  border: none;
  padding:20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}

.btn-info:hover {
  background-color: orange;
  border-radius: 100px;
  border: none;
  padding: 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}

.scroll-down {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
  background-color: #21ad21;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
}
.scroll-down:hover {
  opacity: 1;
  -webkit-transition: all .5s ease-in 3s;
  transition: all .5s ease-in 3s;
  background-color: #000;
}

.scroll-down {
  position: absolute;
  bottom: 30px;
  left:50%;
  margin-left: -16px;
  display: block;
  width: 52px;
  height: 52px;
  border: none;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: bounce 2s infinite 2s;
  animation: bounce 2s infinite 2s;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.scroll-down:before {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    display: block;
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid white;
    border-width: 0px 0 2px 2px;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.featuress{
  background-image: url(../images/turckerb.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.featuresss{
  background-image: url(../images/berge1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.featuressss{
  background-image: url(../images/trailer.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.newt {
   background-color: rgba(0, 0, 0, 0.8);
   padding: 30px;
   color: white !important;
   border-top: 5px solid #21ad21;
   height: 100%;
   margin-top:150px;
}

.newt p {
   color: #e6e6e6 !important;
   font-weight: 400;
}
.newt h2 {
   color: #e6e6e6 !important;
   font-weight: 900;
}




.features{
  padding: 100px 0;
}

.features .btn-success {
   margin-bottom: 30px;
   font-weight: 400;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;

}

@media screen and (max-width: 768px){
   .features {
      text-align: center;
   }
}

 #video {
    height: 100vh;
    overflow: hidden;
 }

@media screen and (max-width: 768px){
    #video {
    height: 0;
    overflow: none;
 }
}

@media screen and (max-width: 1024px){
    #video {
    height: 0;
    overflow: none;
 }
}

.btn-default{
  padding: 10px 30px;
  font-size: 1.2em;
  font-weight: 500;
  border: none;
  transition: 0.5s ease-out;
  background-color: #21ad21;
  color: white;
  border-radius: 100px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;

}

.btn-default:hover{
  padding: 10px 30px;
  font-size: 1.2em;
  font-weight: 800;
  background-color: #000;
  color:#fff;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.14) !important;
  border:none;
}

.products {
   /**background-color: #21ad21;**/
   background-color: #000;
   background-image: url(../images/pattern.png);
   padding: 50px 0;
}

.products h2{
  color: #21ad21;
}

.products p {
  color: white;
  font-weight: 400;
  text-align: center !important;
}

.cta {
   display: flex;
   align-items: center;
   justify-content: center;
}

.embed-responsive {
  position: relative;
}
.overlay {
  position: absolute;
  top: 150px;
  left:50px;
  z-index: 2;
}
.overlay h1{
font-size: 50px;
color: white;
font-weight: 400;
}
.overlay p{
font-size: 14px;
color: white;
font-weight: 400;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.6);
  z-index: 2;
}

.fa:hover {
  color: #21ad21;
}

.shipper-direction{
   padding: 100px 0;
   text-align: justify;
}

.shipper h2{
   font-weight: 900;
}

.cta-desktop{
  padding: 30px;
  background-color: black;
}

.cta-desktop h3{
  color: white;
  text-align: justify;
}



@media screen and (max-width: 768px){

  .panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px 0px;
  }
   .panel-default>.panel-heading:hover {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px 0px;
  }
  .panel-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 15px;
      color: inherit;
      text-align: center;
      text-decoration: none;
  }


  .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
    padding: 20px;
  }
}

.panel-default>.panel-heading {
    color: #fff;
    background-color: #21ad21;
    border-color: transparent;
    padding: 10px 0;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: inherit;
    text-align: center;
    text-decoration: none;
}
.panel-title h4 a:hover {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    color: inherit;
    text-align: center;
    text-decoration: none;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0px;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
    padding: 20px;
    font-size: 13px;
}

.estimate{
    padding-top: 100px;
}

.estimate h3{
   font-weight: 400;
}
.estimate p{
   font-weight: 400;
}

.estimate label {
  font-weight: 400;
}

@media screen and (max-width: 768px){
  .copyright{
    text-align: center;
  }
}

.branded {
   padding: 40px;
}
.branded .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    right: 0%;
    margin-right: -10px;
}

.branded .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    left: 0%;
    margin-left: -10px;
}

.checkout--bounce {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
}

.faq-content {
  background-image: linear-gradient(to right bottom, #098509, #087d0a, #07750a, #066e0a, #06660a);
  padding: 100px 0;
}

.faq-content h2{
  text-align: center;
  font-size: 50px;
  color: white;
}

.faq-section {
   padding: 20px 0;
}

.faq-section h5{
   text-align: center;
   color: grey;
}

.faq-section h2, .faq-section h3{
  color: #21ad21;
}



.affix {
    top:0;
    width: 100%;
    z-index: 9999 !important;
    margin-top: 50px;
    background-color: white;
}

.affix ~ .container {
    position: relative;
    top: 50px;
}

.img-circle {
    border-radius: 50%;
    border: 5px solid #68d448;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 16px;
    top: 13px;
    font-size: 19px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
    color: #fff;
    top: 5px;
}

.quote {
   padding: 50px 0px;
   color: white;
   background-image: linear-gradient(to right bottom, #098509, #087d0a, #07750a, #066e0a, #06660a);
}

.quote p {
  color: white;
}

.why p {
  color: white;
}

.whyy h3{
  color: #21ad21;
}

 .panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
}

.input_wrapper{position:relative}
.plastic_select, input[type=url], input[type=text], input[type=tel], input[type=number], input[type=email], input[type=password], select, textarea {
    font-size: 1.25rem;
    line-height: normal;
    padding: .75rem;
    border: 1px solid #C5C5C5;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    color: #555459;
    width: 100%;
    max-width: 100%;
    font-family: 'SF Pro Text',sans-serif;
    margin: 0 0 .5rem;
    -webkit-transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
    -moz-transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
    transition: box-shadow 70ms ease-out,border-color 70ms ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: none;
    height: auto;
}
.no_touch .plastic_select:hover,.no_touch input:hover,.no_touch select:hover,.no_touch textarea:hover{border-color:#2780f8}
.focus,.plastic_select:active,.plastic_select:focus,input[type=url]:active,input[type=url]:focus,input[type=text]:active,input[type=text]:focus,input[type=number]:active,input[type=number]:focus,input[type=email]:active,input[type=email]:focus,input[type=password]:active,input[type=password]:focus,select:active,select:focus,textarea:active,textarea:focus{border-color:#2780f8;box-shadow:0 0 7px rgba(39,128,248,.15);outline-offset:0;outline:0}

.large_bottom_margin {
    margin-bottom: 2rem!important;
}
.split_input{display:table;border-spacing:0}
.split_input_item{display:table-cell;border:1px solid #9e9ea6}
.split_input_item:first-child{border-top-left-radius:0px;border-bottom-left-radius:0px}
.split_input_item:last-child{border-top-right-radius:0px;border-bottom-right-radius:0px}
.split_input_item.focused{border:1px double #2780f8;box-shadow:0 0 7px rgba(39,128,248,.3)}
.split_input_item input {
    height: 5rem;
    text-align: center;
    font-size: 2.5rem;
    border: none;
    background: 0 0;
    box-shadow: none;
    color: #21ad21;
    font-weight: 500;
}
.split_input_item input:active,.split_input_item input:focus,.split_input_item input:hover{box-shadow:none}


.fs_split{position:absolute;overflow:hidden;width:100%;top:0;bottom:0;left:0;right:0;background-color:#e8e8e8;-webkit-transition:background-color .2s ease-out 0s;-moz-transition:background-color .2s ease-out 0s;transition:background-color .2s ease-out 0s}
.fs_split h1{font-size:2.625rem;line-height:3rem;font-weight:300;margin-bottom:2rem}
.fs_split label{margin-bottom:.5rem}
.fs_split .desc{font-size:1.25rem;color:#9e9ea6;margin-bottom:2rem}
.fs_split .email{color:#555459;font-weight:700}
.fs_split .header_error_message{margin:0 11%;padding:1rem 2rem;background:#fff1e1;border:none;border-left:.5rem solid #ffa940;border-radius:.25rem}
.fs_split .header_error_message h3{margin:0}
.fs_split .error_message{display:none;font-weight:700;color:#ffa940}
.fs_split .error input,.fs_split .error textarea{border:1px solid #ffa940;background:#fff1e1}
.fs_split .error input:focus,.fs_split .error textarea:focus{border-color:#fff1e1;box-shadow:0 0 7px rgba(255,185,100,.15)}
.fs_split .error .error_message{display:inline}
.confirmation_code_span_cell{display:table-cell;font-weight:700;font-size:2rem;text-align:center;padding:0 .5rem;width:2rem}
.confirmation_code_state_message{position:absolute;width:100%;opacity:0;-webkit-transition:opacity .2s;-moz-transition:opacity .2s;transition:opacity .2s}
.confirmation_code_state_message.error,.confirmation_code_state_message.processing,.confirmation_code_state_message.ratelimited{font-size:1.25rem;font-weight:700;line-height:2rem}
.confirmation_code_state_message.processing{color:#3aa3e3}
.confirmation_code_state_message.error,.confirmation_code_state_message.ratelimited{color:#ffa940}
.confirmation_code_state_message ts-icon:before{font-size:2.5rem}
.confirmation_code_state_message svg.ts_icon_spinner{height:2rem;width:2rem}
.confirmation_code_checker{position:relative;height:12rem;text-align:center}
.confirmation_code_checker[data-state=unchecked] .confirmation_code_state_message.unchecked,.confirmation_code_checker[data-state=error] .confirmation_code_state_message.error,.confirmation_code_checker[data-state=processing] .confirmation_code_state_message.processing,.confirmation_code_checker[data-state=ratelimited] .confirmation_code_state_message.ratelimited{opacity:1}
.large_bottom_margin {
    margin-bottom: 2rem !important;
}

@media screen and (max-width: 767px){
   .navbar-default {
      background-color: black;
      border-color: transparent;
      z-index: 99;
   }
  .navbar-default .navbar-nav>li>a {
      color: white;
      font-weight: 400;
      font-family: 'SF Pro Text';
      font-size: 14px;
   }
   .navbar-default .navbar-nav>li>a:hover {
      color: white;
      font-weight: 700;
      font-family: 'SF Pro Text';
      font-size: 14px;
   }
   .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: white;
    background-color: transparent;
    border-bottom: 3px solid #21ad21;
   }
}
@media screen and (max-width: 767px){
   .navbar-inverse {
      background-color: white;
      border-color: transparent;
      z-index: 99;
   }
  .navbar-inverse .navbar-nav>li>a {
      color: black;
      font-weight: 400;
      font-family: 'SF Pro Text';
      font-size: 14px;
   }
   .navbar-inverse .navbar-nav>li>a:hover {
      color: black;
      font-weight: 700;
      font-family: 'SF Pro Text';
      font-size: 14px;
   }
   .navbar-inverse .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: #000;
    background-color: transparent;
    border-bottom: 3px solid #21ad21;
   }

   .navbar-toggle .one {
     width: 40px;
    }
    .navbar-toggle .three {
       width: 30px;
    }

    .navbar-inverse .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
        background-color: transparent;
    }

    .navbar-inverse .navbar-toggle .icon-bar {
      background-color:#21ad21;
  }

    .navbar-inverse .navbar-toggle {
      border-color: transparent;
  }

}

.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: #fff;
}

.panel-title a:hover{
  color: white;
  text-decoration: none;
}

/*----preloader---*/
/* The Loader */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #21ad21;
  -webkit-animation: spin 1.8s linear infinite;
  animation: spin 1.8s linear infinite;
  z-index: 11;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #01ff01;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: black;
  -webkit-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 10;
}
.loaded #loader {
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}
/*---end-preloader---*/



/*****Alert*****/
.alert{
 padding:0;
 margin-top:10px;
 height:40px
}
.alert .fa, .alert .fas {
    font-weight: 600;
    font-size: 12px;
    top: 15px;
    position: absolute;
}
.alert-danger {
color: #ffffff;
background-color: #f5365c;
border-color: #f5365c;
box-shadow: 1px 2px 10px rgba(245, 54, 92, 0.67);
position: relative;
}

.alert-warning{
   position: relative;
}
.alert-success{
   position: relative;
}
.close:not(:disabled):not(.disabled) {
cursor: pointer;
}
.alert-dismissible .close {
    position: absolute;
    top: 8px;
    right: 0;
    font-weight: 300;
    padding: 10px 15px;
    color: inherit;
}
.alert-dismissible .close {
    position: absolute;
    top: 5px;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
.close {
    font-size: 1.4rem;
    font-weight: 400;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}

.alert .contrast-alert {
  background-color: rgba(255, 255, 255, 0.2);
}

.alert .alert-message {
        display: table-cell;
        padding: 12px 15px 20px 15px;
        font-size: 12px;
    }
.alert .alert-icon {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 60px;
    font-size: 20px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.alert-warning {
    color: #fff;
    background-color: #fb6340;
    border-color: #fb6340;
    box-shadow: 1px 2px 10px rgba(251, 99, 64, 0.67);
}





#load{
  width:100%;
  height:100%;
  position:fixed;
  z-index:1000;
  background:url("../images/loader1.png") no-repeat center center rgba(255,255,255,1)
}
#load2{
  width:100%;
  height:100%;
  position:fixed;
  z-index:9999;
  background:url("../images/loader2.png") no-repeat center center rgba(255,255,255,0);
  
}

@keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 4s linear infinite;
}




/***Navbar Breakpoint on Tablet***/

@media (max-width: 992px) {
  .navbar-default{
    background-color: black;
  }
  .navbar-inverse{
    background-color: white;
  }

  .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #21ad21;
   }

   .navbar-toggle{
      border: none;
   }
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }

  .adjust{
    margin-top:20px;
  }

  .adjust h2{
    margin-top: 100px;
    font-size: 18px;
  }

  .become {
    padding: 50px 0;
}

  .media-heading {
    color: #228B22;
    font-weight: 800;
    font-size: 15px !important;
  }

  .products h2{
    font-size: 20px;
  }
  .products p {
    color: white;
    font-weight: 400;
    text-align: center !important;
    font-size: 13px !important ;
  }
  .media-body p {
    font-weight: 400;
    font-size: 13px !important;
    text-align: left !important;
  }
}



.big-wrapper{
            
            height: 100vh;
            width: 100%;
            background-image: linear-gradient(
              to right bottom,
              rgba(0,0,0,0.8), 
              rgba(0,0,0,0.5)), 
            url(../images/truck_bg.jpg);
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 0 20% 0 20% !important;
         }
         .big-wrapper h1{
            font-size: 40px;
            color: white;
            font-weight: 400;
         }

         .big-wrapper .overlay {
            position: absolute;
            top: 150px;
            left: 50px;
            z-index: 2;
        }

        @media screen and (max-width: 768px){
            .big-wrapper .overlay {
                position: absolute;
                top: 150px;
                left: 50px;
                right: 50px;
                z-index: 2;
            }

            .big-wrapper{
              height: 100vh;
            }

            .big-wrapper h1{
                font-size: 30px;
                color: white;
                font-weight: 400;
             }

             .big-wrapper p{
                font-size: 14px;
             }
        }
       
        .faq-content{
		
          background:url('../images/FAQ.png');
          background-size: cover;
          background-position: center center;
          background-repeat: none;
        }
        
        .faq-section h4{
          color: #1e1d1d;
          font-weight: 400;
          margin-top: 20px;
        }
        .faq-content-privacy{
		
          background:url('../images/privacypolicy.png');
          background-size: cover;
          background-position: center center;
          background-repeat: none;
          padding: 100px 0;
        }
      
        .faq-section-privacy h4{
          color: #1e1d1d;
          font-weight: 400;
          margin-top: 20px;
        }

        .faq-content-privacy h2 {
          text-align: center;
          font-size: 50px;
          color: white;
        }

        .faq-section-privacy p{
            text-align: justify;
            font-size: 12px;
        }  
        
        .faq-section-privacy{
          padding: 50px 0;
        }
.faq-content-terms{
		
		background:url('../images/terms-and-condition.png');
		background-size: cover;
		background-position: center center;
    background-repeat: none;
     padding: 100px 0;
	}

	.faq-section-terms h4{
		color: #21ad21;
		font-weight: 400;
		margin-top: 20px;
		text-transform: uppercase;
	}
   .faq-content-terms h2 {
          text-align: center;
          font-size: 50px;
          color: white;
        }
	.faq-section-terms p{
		text-align: justify;
	}

	.faq-section-terms strong{
		color: #21ad21;
  }
  
  .faq-section-terms{
    padding: 50px 0;
  }

  .faq-content-shipper{
		
		background:url('../images/terms-and-condition.png');
		background-size: cover;
		background-position: center center;
    background-repeat: none;
    padding: 100px 0;
  }
  
  .faq-content-shipper h2 {
          text-align: center;
          font-size: 50px;
          color: white;
        }

	.faq-section-shipper h4{
		color: #21ad21;
		font-weight: 400;
		margin-top: 20px;
		text-transform: uppercase;
	}

	.faq-section-shipper p{
		text-align: justify;
	}

	.faq-section-shipper strong {
		color: #21ad21;
  }
  
  .faq-section-shipper{
    padding: 50px 0;
  }

  .login-page {
    height: 100%;
  }

  .login-left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100%;
    color: #fff;
  }

  .login-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../images/login2.jpg");
    background-size: cover;
    background-color: #21ad21;
    height: 100%;
    color: #3d231b;
  }

  @media screen and (max-width: 1200px) {
    .login-left {
      height: 100%;
    }

    .login-right {
      height: 50%;
    }
  }

    .intro-carrier {
        height: 100%
    }

    .carrier-left {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        height: 100%;
        color: #FFF;

    }

    .carrier-right {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #21ad21;
        background-image: url(../images/carrier_signup_bg.jpg);
        background-size: cover;
        background-position: center center;
        height: 100%;
        color: #3d231b;
    }

    @media screen and (max-width: 1200px) {
        .carrier-left {
            height: 100%
        }

        .carrier-right {
            height: 50%
        }

    }

    .intro-driver {
        height: 100%
    }

    .driver-left {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        height: 100%;
        color: #FFF;

    }

    .driver-right {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #21ad21;
        background-image: url(../images/driver-signup-bg.jpg);
        background-size: cover;
        background-position: center right;
        height: 100%;
        color: #3d231b;
    }

    @media screen and (max-width: 1200px) {
        .driver-left {
            height: 100%
        }

        .driver-right {
            height: 50%
        }

    }

    .intro-shipper {
        height: 100%
    }

    .shipper-left {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        /**background-image: url(images/pattern.png);**/
        height: 100%;
        color: #FFF;

    }

    .shipper-right {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #21ad21;
        background-image: url(../images/shipper_signup_bg.jpg);
        background-size: cover;
        height: 100%;
        color: #3d231b;
    }

    @media screen and (max-width: 1200px) {
        .shipper-left {
            height: 100%
        }

        .shipper-right {
            height: 50%
        }

    }